<template>
  <b-card
    no-body
  >
    <b-modal
      id="dpk-settings-modal"
      ref="dpkModalRef"
      :title="id ? 'Update .dpk file' :'Add new .dpk file'"
      centered
      hide-footer
      size="lg"
      @hide="closeModal"
    >
      <b-form>
        <validation-observer ref="wifiSettingsModal">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Model Name:"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Model Name"
                  vid="type"
                  rules="required"
                >
                  <v-select
                    v-model="form.type"
                    :options="options"
                    :searchable="true"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Model Name:"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Model Name"
                  vid="list"
                  rules="required"
                >
                  <b-form-file
                    v-model="form.file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept=".dpk"
                    :state="Boolean(form.file)"
                    class="upload-file"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-button-request
                :text="id ? 'Update' : 'Add'"
                :request="addItem"
                class-container="d-flex justify-content-end"
              />
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>

import {
  BCard, BRow, BCol, BModal, BForm, BFormGroup, BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'
import vSelect from 'vue-select'
import axios from '../../axios-resolver'
import BButtonRequest from '../../../../components/BButtonRequest.vue'

export default {
  components: {
    BButtonRequest,
    BRow,
    BCol,
    BCard,
    BModal,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    vSelect,
    BFormFile,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    getDPK: {
      type: Function,
      default: () => {},
    },
    toEdit: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      max,
      id: null,
      form: {
        type: '',
        file: null,
      },
    }
  },
  watch: {
    toEdit() {
      if (this.toEdit) {
        this.$refs.dpkModalRef.show()
        this.id = this.toEdit.id
        this.form = {
          type: this.options.find(el => this.toEdit.model_type === el.id),
          file: this.toEdit.file,
        }
      } else {
        this.$refs.dpkModalRef.hide()
      }
    },
  },
  methods: {
    async addItem() {
      const valid = await this.$refs.wifiSettingsModal.validate()
      if (valid) {
        try {
          // create FormData and post to '/hardware/model-dpk-files'
          const formData = new FormData()
          formData.append('type', this.form.type.id)
          if (typeof this.form.file === 'object') {
            formData.append('file', this.form.file)
          }

          const url = this.id ? `/hardware/model-dpk-files/${this.id}` : '/hardware/model-dpk-files'
          await axios.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          this.$bvModal.hide('wifi-settings-modal')
          this.closeModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          if (error.response && error.response.status === 422) {
            const { fields } = error.response.data
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
            this.$refs.wifiSettingsModal.setErrors(fields)
          }
        }
      }
    },
    closeModal() {
      this.form = {}
      this.id = null
      this.getDPK()
      this.$refs.dpkModalRef.hide()
    },
  },
}
</script>

<style lang="scss">
</style>

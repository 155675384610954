<template>
  <b-card
    no-body
    class="no-padding p-0"
  >
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      title="Are you sure you want to permanently delete this record?"
      centered
      ok-title="Delete"
      @ok="deleteRow"
    >
      <div>
        Permanently deleted records cannot be recovered later.
      </div>
    </b-modal>
    <div class="section-block m-2">
      <div class="site-hardware-actions site-hardware-actions_management mb-2">

        <div class="section-block__title mb-0">
          <h2>DPK List</h2>
        </div>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.dpk-settings-modal
            variant="primary"
          >
            <feather-icon
              icon="PlusIcon"
              width="16"
              height="16"
              class="mr-25"
            />
            Add Entry
          </b-button>
        </div>
      </div>
      <b-card-body>
        <vue-good-table
          ref="VueGoodTable"
          :is-loading="isLoading"
          :columns="fields"
          :rows="items"
          mode="remote"
          compact-mode
          :total-rows="pagination.total"
          :pagination-options="{
            enabled: true,
            perPage: listParams.perPage,
            setCurrentPage: listParams.page
          }"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: {field: orderBy, type: sortedBy}
          }"
          @on-sort-change="onSortListChange"
          @on-column-filter="onColumnFilter"
        >
          <template slot="loadingContent">
            <b-spinner
              variant="primary"
              label="Spinning"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    class="link-black"
                    :href="props.row.dpk_file"
                    exact-path
                    target="_blank"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>Download build file</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click.prevent="() => toEdit = props.row"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="!props.row.is_default"
                    @click.prevent="deleteHandler(props.row.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="listParams.perPage"
                  :options="pageSizeOptions"
                  class="mx-1"
                  @change="perPageChanged"
                />
                <span class="text-nowrap"> of {{ pagination.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-if="pagination.last_page !== 1"
                  :value="listParams.page"
                  :total-rows="pagination.total"
                  :per-page="listParams.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="changePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </div>

    <AddEntryModal
      :to-edit="toEdit"
      :options="options"
      :get-d-p-k="getDPK"
    />
  </b-card>

</template>

<script>

import {
  BCard, BCardBody, BDropdown, BDropdownItem, BButton, BSpinner,
  BPagination,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, max } from '@validations'
import Ripple from 'vue-ripple-directive'

import { VueGoodTable } from 'vue-good-table'
import axios from '../../axios-resolver'
import AddEntryModal from './AddEntryModal.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCardBody,
    VueGoodTable,
    AddEntryModal,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      items: [],
      isLoading: true,
      options: [],

      toEdit: null,
      pagination: {},
      listParams: {
        page: 1,
        perPage: 25,
        searchJoin: 'and',
      },
      pageSizeOptions: [25, 50, 75, 100],
      search: '',
      searchFields: '',
      orderBy: '',
      sortedBy: '',

      recordToDelete: null,
      fields: [
        {
          field: 'id',
          label: 'ID',
        },
        {
          field: 'model_type',
          label: 'Model',
        },
        {
          field: 'created_at',
          label: 'Created',
        },
        {
          field: 'updated_at',
          label: 'Updated',
        },
        {
          field: 'action',
          label: 'Actions',
          sortable: false,
          thClass: 'action-th-width',
        },
      ],
      required,
      max,
    }
  },
  watch: {
    recordToDelete(val) {
      if (val) {
        this.$refs.modalDelete.show()
      } else {
        this.$refs.modalDelete.hide()
      }
    },
  },
  created() {
    this.getDPK()
    this.getOptions()
  },
  methods: {
    onSortListChange(params) {
      this.orderBy = params[0].field
      this.sortedBy = params[0].type
      this.getDPK()
    },
    perPageChanged(val) {
      this.listParams.perPage = val
      this.getDPK()
    },
    changePage(val) {
      this.listParams.page = val
      this.getDPK()
    },
    async getOptions() {
      try {
        const { data: { options } } = await axios.get('/hardware/model-dpk-files/options')
        this.options = options.types.data
      } catch (error) {
        console.log(error)
      }
    },
    async getDPK() {
      const sortOptionURL = this.orderBy && this.sortedBy ? `&orderBy=${this.orderBy}&sortedBy=${this.sortedBy}` : ''
      const searchURL = this.search ? `&search=${this.search}&searchFields=${this.searchFields}` : ''
      const { data } = await axios.get(`/hardware/model-dpk-files?page=${this.listParams.page}&perPage=${this.listParams.perPage}&searchJoin=${this.listParams.searchJoin}${sortOptionURL}${searchURL}`)
      this.items = data.data.map(item => ({
        ...item,
        is_active: item.disabled === 'false',
      }))
      this.pagination = data.meta.pagination
      this.isLoading = false
    },
    async deleteHandler(id) {
      this.recordToDelete = id
    },
    async deleteRow() {
      try {
        await axios.delete(`/hardware/model-dpk-files/${this.recordToDelete}`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        this.getDPK()
      } catch (error) {
        console.warn(error)
      }
    },
  },
}
</script>

<style lang="scss">

.no-padding {
  .card-body{
    padding: 0;
  }
}
</style>
